import React from "react";
import Container from "./Container";
import Columns from "./Columns";

const ContainerWithCenteredColumns = props => (
  <Container className={`${props.className}`}>
    <Columns className="is-centered">{props.children}</Columns>
  </Container>
);

export default ContainerWithCenteredColumns;
