import React from "react";
import PropTypes from "prop-types";
// import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ContainerWithCenteredColumns from "../components/ContainerWithCenteredColumns";

export const ArticlePostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  author,
  date,
  url,
  slug,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <section className="section">
      {helmet || ""}
      <ContainerWithCenteredColumns className="content">
        <div className="column is-8">
          <h1
            className="title is-size-2 has-text-weight-bold is-bold-light"
            style={{ marginBottom: 24, marginTop: 16 }}
          >
            {title}
          </h1>
          <div className="author">
            By {author} | {date}
          </div>
          <p className="article-description">{description}</p>
          <PostContent content={content} style={{ marginBottom: `2rem` }} />
        </div>
      </ContainerWithCenteredColumns>
    </section>
  );
};

ArticlePostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  author: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string,
  slug: PropTypes.string,
};

const ArticlePost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ArticlePostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Article">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:image"
              content={`${data.site.siteMetadata.siteUrl}${post.frontmatter.featuredImage.childImageSharp.sizes.src}`}
            />
            <meta name="twitter:site" content="@TTRSocial" />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content={`${data.site.siteMetadata.siteUrl}${post.fields.slug}`}
            />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta
              property="og:description"
              content={post.frontmatter.description}
            />
            <meta property="og:site_name" content="The Technical Review" />
            <meta
              property="og:image"
              content={`${data.site.siteMetadata.siteUrl}${post.frontmatter.featuredImage.childImageSharp.sizes.src}`}
            />
          </Helmet>
        }
        featuredImage={post.frontmatter.featuredImage}
        author={post.frontmatter.author}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        url={data.site.siteMetadata.siteUrl}
        slug={post.fields.slug}
      />
    </Layout>
  );
};

ArticlePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ArticlePost;

export const pageQuery = graphql`
  query ArticlePostByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        author
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 900) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`;
